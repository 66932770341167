
import {  useState , useEffect } from 'react'
import Modal from 'react-modal';

import {showError, showSuccess} from '../components/commonFuncs'
import {Routes, Route, useNavigate, redirect, useSearchParams } from 'react-router-dom';

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set,onValue, } from "firebase/database";
import logo from'../assets/logo.png';

import { TbSquare,TbX, TbSquareCheckFilled } from "react-icons/tb";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '60vh',
    width : '90%',
    
  },
};
function UserFinish() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const un = searchParams.get("un");

  console.log(un);
  

  const config = {
    apiKey: "AIzaSyBmWFe5xfI58rgbHaFCTEmWpObudVb5UjE",
    authDomain: "bmw2024-7c13a.firebaseapp.com",
    databaseURL: "https://bmw2024-7c13a-default-rtdb.firebaseio.com",
    projectId: "bmw2024-7c13a",
    storageBucket: "bmw2024-7c13a.appspot.com",
    messagingSenderId: "649690424183",
    appId: "1:649690424183:web:f1874774bd12220f58b772"
    
  };
  const f_app = initializeApp(config);
  const db = getDatabase(f_app);
  const dbRef = ref(db);





  return (
    <div className=' text-gray-900 p-8'>


      <div className='flex justify-center'>
        <a href={'/user/signup?un='+un}>
          <img src={logo} alt="logo" width={270} />
        </a>
      </div>

      <div className='text-2xl font-semibold mt-10 text-center'>Kayıt başarılı.</div>
      <div className='text-lg mt-2 text-center'>Şu an ad ve soyadının ilk harflerini ekranda görüyor olmalısın.</div>
      <div className='text-lg mt-10 text-center'>Artık bu sekmeyi kapatabilirsin.</div>

    </div>

  );
}

export default UserFinish;