
import {  useState , useEffect} from 'react'
import { Link } from 'react-router-dom';

import logo from'../assets/logo.png';
import qr1 from'../assets/qr1.png';
import qr2 from'../assets/qr2.png';
import tv_footer from'../assets/tv_footer.png';
import {showError, showSuccess} from '../components/commonFuncs'
import {Routes, Route, useNavigate, redirect} from 'react-router-dom';

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set,onValue, } from "firebase/database";



function TvLogin() {
  const navigate = useNavigate();

  const config = {
    apiKey: "AIzaSyBmWFe5xfI58rgbHaFCTEmWpObudVb5UjE",
    authDomain: "bmw2024-7c13a.firebaseapp.com",
    databaseURL: "https://bmw2024-7c13a-default-rtdb.firebaseio.com",
    projectId: "bmw2024-7c13a",
    storageBucket: "bmw2024-7c13a.appspot.com",
    messagingSenderId: "649690424183",
    appId: "1:649690424183:web:f1874774bd12220f58b772"
    
  };
  const f_app = initializeApp(config);
  const db = getDatabase(f_app);
  const dbRef = ref(db);

  const [user1_key, setuser1_key] = useState("");
  const [user1_name, setuser1_name] = useState("");
  const [user2_key, setuser2_key] = useState("");
  const [user2_name, setuser2_name] = useState("");
  const [started, setstarted] = useState(0);


  //const [email, setEmail] = useState("");
  //const [soru_aktif, setsoru_aktif] = useState(0);



  useEffect(() => {

    const unsubscribe1 = onValue(ref(db, 'user1_key'), (snapshot) => {
      setuser1_key(snapshot.val())
    });

    const unsubscribe2 = onValue(ref(db, 'user2_key'), (snapshot) => {
      setuser2_key(snapshot.val())
    });

    const unsubscribe3 = onValue(ref(db, 'started'), (snapshot) => {
      setstarted(snapshot.val())
    });




    return () => { unsubscribe1(); unsubscribe2(); unsubscribe3();};

  }, [])

  useEffect(() => {
    if(user1_key !== ""){

      get(child(dbRef, "users/"+user1_key+"/name")).then((snapshot) => {
        if (snapshot.exists()) {
          let isim= snapshot.val().split(" ");
          let isim_toplam = ""
          isim.forEach(element => {
            isim_toplam = isim_toplam + element.charAt(0) + "** "
          });

          setuser1_name(isim_toplam)
        } 
      })

    }

  }, [user1_key])


  useEffect(() => {
    if(user2_key !== ""){

      get(child(dbRef, "users/"+user2_key+"/name")).then((snapshot) => {
        if (snapshot.exists()) {
          let isim= snapshot.val().split(" ");
          let isim_toplam = ""
          isim.forEach(element => {
            isim_toplam = isim_toplam + element.charAt(0) + "** "
          });
          setuser2_name(isim_toplam)
        } 
      })

    }

  }, [user2_key])



  
  useEffect(() => {
    if(started === 1){
            
      navigate('/ekran/soru')
      
    }

  }, [started])
  
  

  return (
    <div className='tvbg h-screen text-gray-900'>

      <div className='flex justify-center mt-10'>
        <img src={logo} alt="logo" width={450} />
      </div>

      <div className='text-center text-6xl font-bold my-8'>QR KODU OKUTARAK KAYIT OL!</div>

      <div className='flex justify-center gap-40 mt-24'>

        {user1_key !== "" ?
        <div className='qr_div flex flex-col justify-center'>
          <div className='text-3xl'>Hoş geldin,</div>
          <div className='font-semibold text-5xl mt-3'>{user1_name}</div>
          <div className='text-2xl mt-5'>Yarışma birazdan başlayacak...</div>
        </div>
        :
        <div>
          <img src={qr1} alt="logo" width={450} />
        </div>
        }

        <div className='bg-black w-2 rounded-full'></div>


        {user2_key !== "" ?
        <div className='qr_div flex flex-col justify-center'>
          <div className='text-3xl'>Hoş geldin,</div>
          <div className='font-semibold text-5xl mt-3'>{user2_name}</div>
          <div className='text-2xl mt-5'>Yarışma birazdan başlayacak...</div>
        </div>
        :
        <div>
          <img src={qr2} alt="logo" width={450} />
        </div>
        }

      </div>
  
      <div className='flex justify-center mt-10 absolute bottom-10 left-0 right-0'>
        <img src={tv_footer} alt="logo" width={560} />
      </div>
  
    </div>

  );
}

export default TvLogin;