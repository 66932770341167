
import {  useState , useEffect} from 'react'
import { Link } from 'react-router-dom';

import {showError, showSuccess} from '../components/commonFuncs'
import {Routes, Route, useNavigate, redirect} from 'react-router-dom';

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set,onValue, } from "firebase/database";

import basliklar_json from'../jsons/baslik.json';
import soru_0 from'../jsons/soru_0.json';
import soru_1 from'../jsons/soru_1.json';
import soru_2 from'../jsons/soru_2.json';
import soru_3 from'../jsons/soru_3.json';
import soru_4 from'../jsons/soru_4.json';
import soru_5 from'../jsons/soru_5.json';
import soru_6 from'../jsons/soru_6.json';
import soru_7 from'../jsons/soru_7.json';
import soru_8 from'../jsons/soru_8.json';


function AdminSoru() {
  const navigate = useNavigate();

  const config = {
    apiKey: "AIzaSyBmWFe5xfI58rgbHaFCTEmWpObudVb5UjE",
    authDomain: "bmw2024-7c13a.firebaseapp.com",
    databaseURL: "https://bmw2024-7c13a-default-rtdb.firebaseio.com",
    projectId: "bmw2024-7c13a",
    storageBucket: "bmw2024-7c13a.appspot.com",
    messagingSenderId: "649690424183",
    appId: "1:649690424183:web:f1874774bd12220f58b772"
    
  };
  const f_app = initializeApp(config);
  const db = getDatabase(f_app);
  const dbRef = ref(db);

  const [user1_key, setuser1_key] = useState("");
  const [user1_name, setuser1_name] = useState("");
  const [user1_star, setuser1_star] = useState(0);
  const [user1_cevap, setuser1_cevap] = useState(100);

  const [user2_key, setuser2_key] = useState("");
  const [user2_name, setuser2_name] = useState("");
  const [user2_star, setuser2_star] = useState(0);
  const [user2_cevap, setuser2_cevap] = useState(100);
  
  
  const [selected_baslik, setselected_baslik] = useState("");
  const [active_index, setactive_index] = useState(0);
  const [selected_sorular, setselected_sorular] = useState([]);
  const [selected_soru, setselected_soru] = useState("");
  const [selected_soru_cevap, setselected_soru_cevap] = useState(0);
  const [ilk_cevap_veren_user, setilk_cevap_veren_user] = useState(100);



  useEffect(() => {


    const unsubscribe1 = onValue(ref(db, 'active_index'), (snapshot) => {
      setactive_index(snapshot.val())
    });

    return () => { unsubscribe1(); };

  }, [])



  const get_data = async () =>  {

    //users
    let _user1_key = ""
    let _user2_key = ""

    await get(child(dbRef, "user1_key")).then((snapshot) => {
      _user1_key = snapshot.val()
      setuser1_key(_user1_key)
    })
    await get(child(dbRef, "user2_key")).then((snapshot) => {
      _user2_key = snapshot.val()
      setuser2_key(_user2_key)
    })







    let baslikid = 0
    let _selected_sorular = ""
    let _active_index = 0

    await get(child(dbRef, "selected_baslik")).then((snapshot) => {
      baslikid = snapshot.val()
    })

    basliklar_json.basliklar.forEach(element => {
      if(element.id === baslikid){
        setselected_baslik(element.baslik)
      }
    });

    await get(child(dbRef, "selected_sorular")).then((snapshot) => {
      _selected_sorular = snapshot.val()
      const aa = _selected_sorular.split(',');
      setselected_sorular(aa)
    })
    await get(child(dbRef, "active_index")).then((snapshot) => {
      _active_index = snapshot.val()
    })

    const aa2 = _selected_sorular.split(',');

    let soruindex = aa2[_active_index]

    if(baslikid === 0){
      setselected_soru(soru_0.sorular[soruindex].soru)
      setselected_soru_cevap(soru_0.sorular[soruindex].cevap)
    }
    if(baslikid === 1){
      setselected_soru(soru_1.sorular[soruindex].soru)
      setselected_soru_cevap(soru_1.sorular[soruindex].cevap)
    }
    if(baslikid === 2){
      setselected_soru(soru_2.sorular[soruindex].soru)
      setselected_soru_cevap(soru_2.sorular[soruindex].cevap)
    }
    if(baslikid === 3){
      setselected_soru(soru_3.sorular[soruindex].soru)
      setselected_soru_cevap(soru_3.sorular[soruindex].cevap)
    }
    if(baslikid === 4){
      setselected_soru(soru_4.sorular[soruindex].soru)
      setselected_soru_cevap(soru_4.sorular[soruindex].cevap)
    }
    if(baslikid === 5){
      setselected_soru(soru_5.sorular[soruindex].soru)
      setselected_soru_cevap(soru_5.sorular[soruindex].cevap)
    }
    if(baslikid === 6){
      setselected_soru(soru_6.sorular[soruindex].soru)
      setselected_soru_cevap(soru_6.sorular[soruindex].cevap)
    }
    if(baslikid === 7){
      setselected_soru(soru_7.sorular[soruindex].soru)
      setselected_soru_cevap(soru_7.sorular[soruindex].cevap)
    }
    if(baslikid === 8){
      setselected_soru(soru_8.sorular[soruindex].soru)
      setselected_soru_cevap(soru_8.sorular[soruindex].cevap)
    }

    


  }


  useEffect(() => {
    if(user1_key !== ""){

      get(child(dbRef, "users/"+user1_key+"/name")).then((snapshot) => {
        
        setuser1_name(snapshot.val())
      })
      get(child(dbRef, "users/"+user1_key+"/star")).then((snapshot) => {
        setuser1_star(snapshot.val())
      })

    }

  }, [user1_key,active_index])


  useEffect(() => {
    if(user2_key !== ""){

      get(child(dbRef, "users/"+user2_key+"/name")).then((snapshot) => {
        setuser2_name(snapshot.val())
      })
      get(child(dbRef, "users/"+user2_key+"/star")).then((snapshot) => {
        setuser2_star(snapshot.val())
      })

    }

  }, [user2_key,active_index])




  useEffect(() => {
    
    get_data()

  }, [active_index])



  

  const  kaydet = () => {

    let _ready = 1

    if(user1_cevap === 100){
      _ready = 0
      showError("Yarışmacı 1'in cevabını kaydetmelisiniz.")
    }
    if(user2_cevap === 100){
      _ready = 0
      showError("Yarışmacı 2'in cevabını kaydetmelisiniz.")
    }
    if(ilk_cevap_veren_user === 100){
      _ready = 0
      showError("İlk cevabı veren yarışmacıyı seçiniz.")
    }

    if(_ready === 1){

      let kazanan = 0

      console.log("ilk_cevap_veren_user");
      console.log(ilk_cevap_veren_user);
      console.log("user1_cevap");
      console.log(user1_cevap);
      console.log("dogru_cevap");
      console.log(selected_soru_cevap);
      

      if(ilk_cevap_veren_user === 1){
        if(user1_cevap === selected_soru_cevap){
          
          kazanan = 1
        }
        if(kazanan === 0){
          if(user2_cevap === selected_soru_cevap){
            kazanan = 2
          }
        }
      }else{

        if(user2_cevap === selected_soru_cevap){
          kazanan = 2
        }
        if(kazanan === 0){
          if(user1_cevap === selected_soru_cevap){
            kazanan = 1
          }
        }



      }
  

      console.log("kazanan");
      console.log(kazanan);
      
  
      let bitti = 0

      if(kazanan === 1){
        
        let yeni_star = user1_star + 1
        set(ref(db, 'users/'+user1_key+"/star"), yeni_star);

        if(yeni_star === 3){bitti = 1}
      }

      if(kazanan === 2){
        
        let yeni_star = user2_star + 1
        set(ref(db, 'users/'+user2_key+"/star"), yeni_star);

        if(yeni_star === 3){bitti = 1}
      }


      //kazananı kaydet
      


      set(ref(db, 'dogru_was'), selected_soru_cevap);
      set(ref(db, 'star_alan'), kazanan);
      set(ref(db, 'cevap_verildi'), "1");
      set(ref(db, 'user1_cevap'), 100);
      set(ref(db, 'user2_cevap'), 100);

      set(ref(db, 'active_index'), active_index+1);
      setuser1_cevap(100)
      setuser2_cevap(100)
      setilk_cevap_veren_user(100)



      
      if(bitti === 1){
        navigate("/admin/finish")
      }
      

      
      
    }


  }

  

  const  sifirla = () => {

    set(ref(db, 'active_index'), 0);
    set(ref(db, 'selected_baslik'), 0);
    set(ref(db, 'selected_sorular'), "");
    set(ref(db, 'user1_key'), "");
    set(ref(db, 'user2_key'), "");
    set(ref(db, 'started'), 0);
    set(ref(db, 'dogru_was'), 0);
    set(ref(db, 'star_alan'), 0);
    set(ref(db, 'cevap_verildi'), "0");
    set(ref(db, 'user1_cevap'), 100);
    set(ref(db, 'user2_cevap'), 100);
    navigate('/admin/home')

  }



  const user1_cevap_kaydet = (ne) =>{
    setuser1_cevap(ne)
    set(ref(db, 'user1_cevap'), ne);
    
  }
  const user2_cevap_kaydet = (ne) =>{
    setuser2_cevap(ne)
    set(ref(db, 'user2_cevap'), ne);
  }



  return (
    <div className='p-10'>

      <div className='flex justify-end mb-10'>
        <button onClick={() => {if(window.confirm('Emin misiniz?')){ sifirla()}}} className='text-white bg-red-600 p-2'>SIFIRLA</button>
      </div>

      <div className='py-2'><span className='font-semibold'>Konu Başlığı</span>: {selected_baslik}</div>
      <div className='py-2'><span className='font-semibold'>Soru {active_index+1}</span> : {selected_soru}</div>
      <div className='py-2'><span className='font-semibold'>Doğru Cevap</span> : {selected_soru_cevap === 0 ? "Hayır" : "Evet" }</div>


      <div className='mt-10 flex space-x-4'>

        <div className="p-3 flex-1 border border-slate-500">
          <div className='font-semibold mb-2'> 1 : {user1_name} ({user1_star} yıldız)</div>

          <div className='mt-5 text-sm'>Verdiği Cevap:</div>
          <div className='flex space-x-2 mt-2'>
            <button onClick={() => user1_cevap_kaydet(1)} className={(user1_cevap === 1 ? "bg-blue-300" : "bg-slate-200") + ' px-4 py-2 '}>Evet</button>
            <button onClick={() => user1_cevap_kaydet(0)} className={(user1_cevap === 0 ? "bg-blue-300" : "bg-slate-200") + ' px-4 py-2 '}>Hayır</button>
          </div>
          
        </div>
        <div className="p-3 flex-1 border border-slate-500">
          <div className='font-semibold mb-2'> 2 : {user2_name} ({user2_star} yıldız)</div>

          <div className='mt-5 text-sm'>Verdiği Cevap:</div>
          <div className='flex space-x-2 mt-3'>
            <button onClick={() => user2_cevap_kaydet(1)} className={(user2_cevap === 1 ? "bg-blue-300" : "bg-slate-200") + ' px-4 py-2 '}>Evet</button>
            <button onClick={() => user2_cevap_kaydet(0)} className={(user2_cevap === 0 ? "bg-blue-300" : "bg-slate-200") + ' px-4 py-2 '}>Hayır</button>
          </div>
          
        </div>

      </div>


      <div className='mt-10'>
        <div>İlk Cevap Veren</div>
        <select value={ilk_cevap_veren_user} onChange={(e) => setilk_cevap_veren_user(parseInt(e.target.value))} className="border border-slate-500 p-3 mt-1 w-full">
            <option value={100}>Seçiniz</option>
            <option value={1}>Yarışmacı 1 : {user1_name}</option>
            <option value={2}>Yarışmacı 2 : {user2_name}</option>
        </select>

      </div>

      <button onClick={kaydet} className='p-3 mt-10 w-full bg-blue-600 text-white '>KAYDET</button>

      
      





    </div>

  );
}

export default AdminSoru;