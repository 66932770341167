import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Routes, Route, BrowserRouter } from "react-router-dom"

import TvLogin from "./components/TvLogin"
import TvSoru from "./components/TvSoru"
import TvCevap from "./components/TvCevap"
import AdminHome from "./components/AdminHome"
import AdminSoru from "./components/AdminSoru"
import AdminFinish from "./components/AdminFinish"
import UserSignup from "./components/UserSignup"
import UserFinish from "./components/UserFinish"

function App() {
  return (
    <div className="App">
        
    <BrowserRouter>
      <Routes>
        <Route  >

          <Route path="/ekran" element={<TvLogin />} />
          <Route path="/ekran/soru" element={<TvSoru />} />
          <Route path="/ekran/cevap" element={<TvCevap />} />
          <Route path="/admin/home" element={<AdminHome />} />
          <Route path="/admin/soru" element={<AdminSoru />} />
          <Route path="/admin/finish" element={<AdminFinish />} />
          <Route path="/user/signup" element={<UserSignup />} />
          <Route path="/user/finish" element={<UserFinish />} />
          


        </Route>
      </Routes>
    </BrowserRouter>
    <ToastContainer/>
  </div>
  );
}

export default App;
