
import {  useState , useEffect, useRef} from 'react'
import { Link } from 'react-router-dom';

import logo from'../assets/logo.png';
import gpt from'../assets/gpt.png';
import cerceve from'../assets/cerceve.png';
import alt from'../assets/alt.png';
import star from'../assets/star.png';
import starts from'../assets/starts.png';

import {showError, showSuccess} from '../components/commonFuncs'
import {Routes, Route, useNavigate, redirect} from 'react-router-dom';

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set,onValue, } from "firebase/database";
import Typewriter from 'typewriter-effect';


import basliklar_json from'../jsons/baslik.json';
import soru_0 from'../jsons/soru_0.json';
import soru_1 from'../jsons/soru_1.json';
import soru_2 from'../jsons/soru_2.json';
import soru_3 from'../jsons/soru_3.json';
import soru_4 from'../jsons/soru_4.json';
import soru_5 from'../jsons/soru_5.json';
import soru_6 from'../jsons/soru_6.json';
import soru_7 from'../jsons/soru_7.json';
import soru_8 from'../jsons/soru_8.json';

function TvSoru() {
  const navigate = useNavigate();

  const count = useRef(null) 

  const config = {
    apiKey: "AIzaSyBmWFe5xfI58rgbHaFCTEmWpObudVb5UjE",
    authDomain: "bmw2024-7c13a.firebaseapp.com",
    databaseURL: "https://bmw2024-7c13a-default-rtdb.firebaseio.com",
    projectId: "bmw2024-7c13a",
    storageBucket: "bmw2024-7c13a.appspot.com",
    messagingSenderId: "649690424183",
    appId: "1:649690424183:web:f1874774bd12220f58b772"
    
  };
  const f_app = initializeApp(config);
  const db = getDatabase(f_app);
  const dbRef = ref(db);

  const [user1_key, setuser1_key] = useState("");
  const [user1_name, setuser1_name] = useState("");
  const [user1_cevap, setuser1_cevap] = useState(100);
  const [user1_star, setuser1_star] = useState(0);
  
  const [user2_key, setuser2_key] = useState("");
  const [user2_name, setuser2_name] = useState("");
  const [user2_cevap, setuser2_cevap] = useState(100);
  const [user2_star, setuser2_star] = useState(0);

  const [started, setstarted] = useState(1);


  const [selected_baslik, setselected_baslik] = useState("");
  const [active_index, setactive_index] = useState(null);
  const [selected_sorular, setselected_sorular] = useState([]);
  const [selected_soru, setselected_soru] = useState("");
  const [cevap_verildi, setcevap_verildi] = useState("0");
  
  const [show_soru, setshow_soru] = useState(false);


  useEffect(() => {

    const unsubscribe1 = onValue(ref(db, 'user1_key'), (snapshot) => {
      setuser1_key(snapshot.val())
    });

    const unsubscribe2 = onValue(ref(db, 'user2_key'), (snapshot) => {
      setuser2_key(snapshot.val())
    });

    const unsubscribe3 = onValue(ref(db, 'started'), (snapshot) => {
      setstarted(snapshot.val())
    });

    const unsubscribe4 = onValue(ref(db, 'active_index'), (snapshot) => {
      setactive_index(snapshot.val())
    });

    const unsubscribe5 = onValue(ref(db, 'cevap_verildi'), (snapshot) => {
      setcevap_verildi(snapshot.val())
    });

    const unsubscribe6 = onValue(ref(db, 'user1_cevap'), (snapshot) => {
      setuser1_cevap(snapshot.val())
    });
    const unsubscribe7 = onValue(ref(db, 'user2_cevap'), (snapshot) => {
      setuser2_cevap(snapshot.val())
    });




    getData()

    return () => { unsubscribe1(); unsubscribe2(); unsubscribe3(); unsubscribe4(); unsubscribe5(); unsubscribe7(); unsubscribe6(); };

  }, [])

  useEffect(() => {
    if(user1_key !== ""){

      get(child(dbRef, "users/"+user1_key+"/name")).then((snapshot) => {
        if (snapshot.exists()) {
          let isim= snapshot.val().split(" ");
          let isim_toplam = ""
          isim.forEach(element => {
            isim_toplam = isim_toplam + element.charAt(0) + "** "
          });
          setuser1_name(isim_toplam)
        } 
      })
      get(child(dbRef, "users/"+user1_key+"/star")).then((snapshot) => {
          setuser1_star(snapshot.val())
      })

    }

  }, [user1_key])


  useEffect(() => {
    if(user2_key !== ""){

      get(child(dbRef, "users/"+user2_key+"/name")).then((snapshot) => {
        if (snapshot.exists()) {
          let isim= snapshot.val().split(" ");
          let isim_toplam = ""
          isim.forEach(element => {
            isim_toplam = isim_toplam + element.charAt(0) + "** "
          });
          setuser2_name(isim_toplam)
        } 
      })

      get(child(dbRef, "users/"+user2_key+"/star")).then((snapshot) => {
          setuser2_star(snapshot.val())
      })

    }

  }, [user2_key])



  
  useEffect(() => {
    if(started === 0){
      navigate('/ekran')
    }
  }, [started])



  const getData = async () => {

    let baslikid = 0
    let _selected_sorular = ""
    let _active_index = 0

    let _selected_soru = ""

    await get(child(dbRef, "selected_baslik")).then((snapshot) => {
      baslikid = snapshot.val()
    })

    basliklar_json.basliklar.forEach(element => {
      if(element.id === baslikid){
        setselected_baslik(element.baslik)
      }
    });

    await get(child(dbRef, "selected_sorular")).then((snapshot) => {
      _selected_sorular = snapshot.val()
      const aa = _selected_sorular.split(',');
      setselected_sorular(aa)
    })

    await get(child(dbRef, "active_index")).then((snapshot) => {
      _active_index = snapshot.val()
    })


    const aa2 = _selected_sorular.split(',');

    let soruindex = aa2[_active_index]

    if(baslikid === 0){
      _selected_soru = soru_0.sorular[soruindex].soru
    }
    if(baslikid === 1){
      _selected_soru = soru_1.sorular[soruindex].soru
    }
    if(baslikid === 2){
      _selected_soru = soru_2.sorular[soruindex].soru
    }
    if(baslikid === 3){
      _selected_soru = soru_3.sorular[soruindex].soru
    }
    if(baslikid === 4){
      _selected_soru = soru_4.sorular[soruindex].soru
    }
    if(baslikid === 5){
      _selected_soru = soru_5.sorular[soruindex].soru
    }
    if(baslikid === 6){
      _selected_soru = soru_6.sorular[soruindex].soru
    }
    if(baslikid === 7){
      _selected_soru = soru_7.sorular[soruindex].soru
    }
    if(baslikid === 8){
      _selected_soru = soru_8.sorular[soruindex].soru
    }

    setselected_soru(_selected_soru)
    

  }

  useEffect(() => {
    if(selected_soru !== "" ){
      setshow_soru(true)
    }
  }, [selected_soru])
  
  useEffect(() => {

    if(cevap_verildi === "1"){
      navigate('/ekran/cevap')
    }
    

  }, [cevap_verildi])
  

  return (
    <div className='tvbg h-screen text-gray-900'>

      <div className='flex justify-center mt-10 items-center space-x-32'>

        <div className='mt-20'>
          <div className='cerceve '>
            <div className='absolute text-3xl font-bold left-0 right-0 mt-9 text-center'>{user1_name}</div>
            <img src={cerceve} alt="logodsd" width={255} />
          </div>
          <div className='flex justify-center space-x-2 mt-1'>
            <img src={user1_star > 0 ? starts : star} alt="logo" width={44} />
            <img src={user1_star > 1 ? starts : star} alt="logo" width={44} />
            <img src={user1_star > 2 ? starts : star} alt="logo" width={44} />
          </div>
        </div>
        
        <img src={logo} alt="logo" width={420} />

        <div className='mt-20'>

          <div className='cerceve '>
            <div className='absolute text-3xl font-bold left-0 right-0 mt-9 text-center'>{user2_name}</div>
            <img src={cerceve} alt="logodsd" width={255} />
          </div>
          <div className='flex justify-center  space-x-2 mt-1'>
            <img src={user2_star > 0 ? starts : star} alt="logo" width={44} />
            <img src={user2_star > 1 ? starts : star} alt="logo" width={44} />
            <img src={user2_star > 2 ? starts : star} alt="logo" width={44} />
          </div>

        </div>


      </div>

      <div className='text-center text-3xl font-bold mt-8 mb-4 border-b-4 border-slate-900 pb-4 w-80 mx-auto'>KONU BAŞLIĞI</div>

      <div className='text-center text-4xl h-20 '>{selected_baslik}</div>


      <div className='text-4xl mx-44 flex space-x-5 mt-10'>

        <div className='w_7e'> <img src={gpt} width={64} /> </div>

        <div className='flex-1'>
          <div className='font-bold mb-3 mt-3'>SORU {active_index === null ? "" : active_index+1}</div>
          {show_soru &&
          <div className='leading-normal'> 
          <Typewriter
          options={{
            autoStart: true,
            delay: 20,
            cursor: '',
          }}
            onInit={(typewriter) => {
              typewriter.typeString(selected_soru).start();
            }}
          />
          </div>
          }
        </div>
        
      </div>


      
      <div className='flex flex-col items-end justify-end mt-8 space-y-5 mx-44'>
        {user1_cevap !== 100 &&
          <div className='bg-gray-200 text-3xl py-3 px-6 rounded-full'>
            <span className="font-semibold">{user1_name}</span> {user1_cevap === 1 ? "Evet" : "Hayır"}
          </div>
        }
        {user2_cevap !== 100 &&
        <div className='bg-gray-200 text-3xl py-3 px-6 rounded-full'>
          <span className="font-semibold">{user2_name}</span> {user2_cevap === 1 ? "Evet" : "Hayır"}
        </div>
        }
      </div>

        
        
        
        
        
        <div className='flex flex-col items-center justify-center mt-10 absolute bottom-8 left-0 right-0 '>
          <img src={alt} width={1000} />
          <div className='mt-3 text-lg text-gray-400'>ChatGPT hata yapabilir. Önemli bilgileri kontrol edin.</div>
        </div>

      
    </div>

  );
}

export default TvSoru;