
import {  useState , useEffect} from 'react'
import { Link } from 'react-router-dom';

import {showError, showSuccess} from '../components/commonFuncs'
import {Routes, Route, useNavigate, redirect} from 'react-router-dom';

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set,onValue, } from "firebase/database";

import basliklar_json from'../jsons/baslik.json';
import soru_0 from'../jsons/soru_0.json';
import soru_1 from'../jsons/soru_1.json';
import soru_2 from'../jsons/soru_2.json';
import soru_3 from'../jsons/soru_3.json';
import soru_4 from'../jsons/soru_4.json';
import soru_5 from'../jsons/soru_5.json';
import soru_6 from'../jsons/soru_6.json';
import soru_7 from'../jsons/soru_7.json';
import soru_8 from'../jsons/soru_8.json';


function AdminHome() {
  const navigate = useNavigate();

  const config = {
    apiKey: "AIzaSyBmWFe5xfI58rgbHaFCTEmWpObudVb5UjE",
    authDomain: "bmw2024-7c13a.firebaseapp.com",
    databaseURL: "https://bmw2024-7c13a-default-rtdb.firebaseio.com",
    projectId: "bmw2024-7c13a",
    storageBucket: "bmw2024-7c13a.appspot.com",
    messagingSenderId: "649690424183",
    appId: "1:649690424183:web:f1874774bd12220f58b772"
    
  };
  const f_app = initializeApp(config);
  const db = getDatabase(f_app);
  const dbRef = ref(db);

  const [user1_key, setuser1_key] = useState("");
  const [user1_name, setuser1_name] = useState("");
  const [user2_key, setuser2_key] = useState("");
  const [user2_name, setuser2_name] = useState("");
  
  const [basliklar, setbasliklar] = useState([]);
  const [selected_baslik, setselected_baslik] = useState(0);



  useEffect(() => {

    setbasliklar(basliklar_json.basliklar)


    const unsubscribe1 = onValue(ref(db, 'user1_key'), (snapshot) => {
      setuser1_key(snapshot.val())
    });

    const unsubscribe2 = onValue(ref(db, 'user2_key'), (snapshot) => {
      setuser2_key(snapshot.val())
    });

   


    return () => { unsubscribe1(); unsubscribe2()};

  }, [])

  useEffect(() => {
    if(user1_key !== ""){

      get(child(dbRef, "users/"+user1_key+"/name")).then((snapshot) => {
        if (snapshot.exists()) {
          let isim= snapshot.val().split(" ");
          setuser1_name(snapshot.val())
        } 
      })

    }

  }, [user1_key])


  useEffect(() => {
    if(user2_key !== ""){

      get(child(dbRef, "users/"+user2_key+"/name")).then((snapshot) => {
        if (snapshot.exists()) {
          let isim= snapshot.val().split(" ");
          setuser2_name(snapshot.val())
        } 
      })

    }

  }, [user2_key])
  

  const siluser1 = () => {
    set(ref(db, 'user1_key'), "");
  }
  const siluser2 = () => {
    set(ref(db, 'user2_key'), "");
  }
  
  const baslat = () => {

    if(user1_key !== "" && user2_key !== ""){
      
          set(ref(db, 'selected_baslik'), parseInt(selected_baslik));
      
          let soruseti = []
      
          // soru belirle
          if(selected_baslik === "0"){ soruseti = soru_0.sorular}
          if(selected_baslik === "1"){ soruseti = soru_1.sorular}
          if(selected_baslik === "2"){ soruseti = soru_2.sorular}
          if(selected_baslik === "3"){ soruseti = soru_3.sorular}
          if(selected_baslik === "4"){ soruseti = soru_4.sorular}
          if(selected_baslik === "5"){ soruseti = soru_5.sorular}
          if(selected_baslik === "6"){ soruseti = soru_6.sorular}
          if(selected_baslik === "7"){ soruseti = soru_7.sorular}
          if(selected_baslik === "8"){ soruseti = soru_8.sorular}
      
          //console.log(soruseti);
      
          let arr = Array(100).fill().map((_, index) => index );
          
          let arr_selecteed = shuffle(arr).slice(0, 15).toString();
          
          set(ref(db, 'selected_sorular'), arr_selecteed);
          set(ref(db, 'active_index'), 0);
          set(ref(db, 'started'), 1);

          navigate('/admin/soru')

    }else{
      showError("2 Kullanıcı da girmiş olmalı")
    }
    
  }

  const shuffle = (array) => { 
    for (let i = array.length - 1; i > 0; i--) { 
      const j = Math.floor(Math.random() * (i + 1)); 
      [array[i], array[j]] = [array[j], array[i]]; 
    } 
    return array; 
  }; 
  


  return (
    <div className='p-20'>

      <div className='font-semibold text-2xl mb-2'>Yarışmacı 1</div>

      {user1_key === "" ?
        <div className="p-3  border border-slate-500">Bekleniyor...</div>
        :
        <div className="flex space-x-3">
        
          <div className="p-3 flex-1 border border-slate-500">{user1_name}</div>
          <button onClick={siluser1} className='p-3  bg-red-600 text-white '>SİL</button>

        </div>
      }




      <div className='font-semibold text-2xl mt-10 mb-2'>Yarışmacı 2</div>

      {user2_key === "" ?
        <div className="p-3  border border-slate-500">Bekleniyor...</div>
        :
        <div className="flex space-x-3">
        
          <div className="p-3 flex-1 border border-slate-500">{user2_name}</div>
          <button onClick={siluser2} className='p-3  bg-red-600 text-white '>SİL</button>

        </div>
      }


      <div className='font-semibold text-2xl mt-10 mb-2'>Konu Başlığı Seç</div>

      <select value={selected_baslik} onChange={(e) => setselected_baslik(e.target.value)} className="border border-slate-500 p-3 w-full">
        
        {basliklar.map((item,index) =>(
          <option key={index} value={item.id}>{item.baslik}</option>
        ))}

      </select>

      <button onClick={baslat} className='p-3 mt-10 w-full bg-blue-600 text-white '>YARIŞMAYI BAŞLAT</button>


    </div>

  );
}

export default AdminHome;