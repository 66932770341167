import { toast } from 'react-toastify';

export function date_tr(gelen) {
    var tarih = new Date(gelen);
    var aylar = new Array("Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık");
    var gunler = new Array("Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi");


    return tarih.getDate() + " " + aylar[tarih.getMonth()] + " " + tarih.getFullYear() + ", "+ gunler[tarih.getDay()]}

export function showError(gelen) {

    toast.error(gelen, {
        position: "top-right", autoClose: 3000,  hideProgressBar: false, closeOnClick: true,
        pauseOnHover: true, draggable: true, progress: undefined,
    });
}

export function showSuccess(gelen) {
    toast.success(gelen, {
        position: "top-right", autoClose: 2000,  hideProgressBar: false, closeOnClick: true,
        pauseOnHover: true, draggable: true, progress: undefined,
    });
}