
import {  useState , useEffect} from 'react'
import { Link } from 'react-router-dom';

import {showError, showSuccess} from '../components/commonFuncs'
import {Routes, Route, useNavigate, redirect} from 'react-router-dom';

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set,onValue, } from "firebase/database";

import basliklar_json from'../jsons/baslik.json';



function AdminFinish() {
  const navigate = useNavigate();

  const config = {
    apiKey: "AIzaSyBmWFe5xfI58rgbHaFCTEmWpObudVb5UjE",
    authDomain: "bmw2024-7c13a.firebaseapp.com",
    databaseURL: "https://bmw2024-7c13a-default-rtdb.firebaseio.com",
    projectId: "bmw2024-7c13a",
    storageBucket: "bmw2024-7c13a.appspot.com",
    messagingSenderId: "649690424183",
    appId: "1:649690424183:web:f1874774bd12220f58b772"
    
  };
  const f_app = initializeApp(config);
  const db = getDatabase(f_app);
  const dbRef = ref(db);

  const [user1_key, setuser1_key] = useState("");
  const [user1_name, setuser1_name] = useState("");
  const [user1_star, setuser1_star] = useState(0);
  const [user1_cevap, setuser1_cevap] = useState(100);

  const [user2_key, setuser2_key] = useState("");
  const [user2_name, setuser2_name] = useState("");
  const [user2_star, setuser2_star] = useState(0);
  const [user2_cevap, setuser2_cevap] = useState(100);
  
  
  const [selected_baslik, setselected_baslik] = useState("");
  const [active_index, setactive_index] = useState(0);
  const [selected_sorular, setselected_sorular] = useState([]);
  const [selected_soru, setselected_soru] = useState("");
  const [selected_soru_cevap, setselected_soru_cevap] = useState(0);
  const [ilk_cevap_veren_user, setilk_cevap_veren_user] = useState(100);



  useEffect(() => {


    get_data()

  }, [])



  const get_data = async () =>  {

    //users
    let _user1_key = ""
    let _user2_key = ""

    await get(child(dbRef, "user1_key")).then((snapshot) => {
      _user1_key = snapshot.val()
      setuser1_key(_user1_key)
    })
    await get(child(dbRef, "user2_key")).then((snapshot) => {
      _user2_key = snapshot.val()
      setuser2_key(_user2_key)
    })


    let baslikid = 0
    let _selected_sorular = ""
    let _active_index = 0

    await get(child(dbRef, "selected_baslik")).then((snapshot) => {
      baslikid = snapshot.val()
    })

    basliklar_json.basliklar.forEach(element => {
      if(element.id === baslikid){
        setselected_baslik(element.baslik)
      }
    });



  }


  useEffect(() => {
    if(user1_key !== ""){

      get(child(dbRef, "users/"+user1_key+"/name")).then((snapshot) => {
        
        setuser1_name(snapshot.val())
      })
      get(child(dbRef, "users/"+user1_key+"/star")).then((snapshot) => {
        setuser1_star(snapshot.val())
      })

    }

  }, [user1_key])


  useEffect(() => {
    if(user2_key !== ""){

      get(child(dbRef, "users/"+user2_key+"/name")).then((snapshot) => {
        setuser2_name(snapshot.val())
      })
      get(child(dbRef, "users/"+user2_key+"/star")).then((snapshot) => {
        setuser2_star(snapshot.val())
      })

    }

  }, [user2_key])








  


  

  const  sifirla = () => {

    set(ref(db, 'active_index'), 0);
    set(ref(db, 'selected_baslik'), 0);
    set(ref(db, 'selected_sorular'), "");
    set(ref(db, 'user1_key'), "");
    set(ref(db, 'user2_key'), "");
    set(ref(db, 'started'), 0);
    set(ref(db, 'dogru_was'), 0);
    set(ref(db, 'star_alan'), 0);
    set(ref(db, 'cevap_verildi'), "0");
    set(ref(db, 'user1_cevap'), 100);
    set(ref(db, 'user2_cevap'), 100);
    navigate('/admin/home')

  }





  return (
    <div className='p-10'>



      <div className='py-2'><span className='font-semibold'>Konu Başlığı</span>: {selected_baslik}</div>


      <div className='mt-10 flex space-x-4'>

        <div className="p-3 flex-1 border border-slate-500">
          <div className='font-semibold mb-2'> 1 : {user1_name} ({user1_star} yıldız)</div>

          {user1_star === 3 && <div className='text-green-500 font-bold'>KAZANDI!</div>}
          
        </div>
        <div className="p-3 flex-1 border border-slate-500">
          <div className='font-semibold mb-2'> 2 : {user2_name} ({user2_star} yıldız)</div>

          {user2_star === 3 && <div className='text-green-500 font-bold'>KAZANDI!</div>}
          
        </div>

      </div>


      

      <button onClick={sifirla} className='p-3 mt-10 w-full bg-blue-600 text-white '>SONLANDIR</button>

      
      





    </div>

  );
}

export default AdminFinish;