
import {  useState , useEffect, useRef} from 'react'
import { Link } from 'react-router-dom';

import logo from'../assets/logo.png';
import gpt from'../assets/gpt.png';
import cerceve from'../assets/cerceve.png';
import alt from'../assets/alt.png';
import star from'../assets/star.png';
import starts from'../assets/starts.png';
import {showError, showSuccess} from '../components/commonFuncs'
import {Routes, Route, useNavigate, redirect} from 'react-router-dom';

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set,onValue, } from "firebase/database";
import Typewriter from 'typewriter-effect';


import basliklar_json from'../jsons/baslik.json';


function TvCevap() {
  const navigate = useNavigate();

  const count = useRef(null) 

  const config = {
    apiKey: "AIzaSyBmWFe5xfI58rgbHaFCTEmWpObudVb5UjE",
    authDomain: "bmw2024-7c13a.firebaseapp.com",
    databaseURL: "https://bmw2024-7c13a-default-rtdb.firebaseio.com",
    projectId: "bmw2024-7c13a",
    storageBucket: "bmw2024-7c13a.appspot.com",
    messagingSenderId: "649690424183",
    appId: "1:649690424183:web:f1874774bd12220f58b772"
    
  };
  const f_app = initializeApp(config);
  const db = getDatabase(f_app);
  const dbRef = ref(db);

  const [user1_key, setuser1_key] = useState("");
  const [user1_name, setuser1_name] = useState("");
  const [user2_key, setuser2_key] = useState("");
  const [user2_name, setuser2_name] = useState("");
  const [user1_star, setuser1_star] = useState(null);
  const [user2_star, setuser2_star] = useState(null);


  const [selected_baslik, setselected_baslik] = useState("");
  const [active_index, setactive_index] = useState(0);
  const [selected_sorular, setselected_sorular] = useState([]);
  const [selected_soru, setselected_soru] = useState("");
  const [yazigoster, setyazigoster] = useState(false);
  
  const [star_alan, setstar_alan] = useState(null);
  const [dogru_was, setdogru_was] = useState(null);
  const [yazi, setyazi] = useState("");
  const [started, setstarted] = useState(1);


  useEffect(() => {

    const unsubscribe1 = onValue(ref(db, 'user1_key'), (snapshot) => {
      setuser1_key(snapshot.val())
    });

    const unsubscribe2 = onValue(ref(db, 'user2_key'), (snapshot) => {
      setuser2_key(snapshot.val())
    });

    const unsubscribe3 = onValue(ref(db, 'started'), (snapshot) => {
      setstarted(snapshot.val())
    });


    getData()

    return () => { unsubscribe1(); unsubscribe2(); unsubscribe3(); };

  }, [])

  useEffect(() => {
    if(user1_key !== ""){

      get(child(dbRef, "users/"+user1_key+"/name")).then((snapshot) => {
        if (snapshot.exists()) {
          let isim= snapshot.val().split(" ");
          let isim_toplam = ""
          isim.forEach(element => {
            isim_toplam = isim_toplam + element.charAt(0) + "** "
          });
          setuser1_name(isim_toplam)
        } 
      })

      get(child(dbRef, "users/"+user1_key+"/star")).then((snapshot) => {
          setuser1_star(snapshot.val())
      })

    }

  }, [user1_key])


  useEffect(() => {
    if(user2_key !== ""){

      get(child(dbRef, "users/"+user2_key+"/name")).then((snapshot) => {
        if (snapshot.exists()) {
          let isim= snapshot.val().split(" ");
          let isim_toplam = ""
          isim.forEach(element => {
            isim_toplam = isim_toplam + element.charAt(0) + "** "
          });
          setuser2_name(isim_toplam)
        } 
      })

      get(child(dbRef, "users/"+user2_key+"/star")).then((snapshot) => {
        setuser2_star(snapshot.val())
      })

      

    }

  }, [user2_key])


  useEffect(() => {
    if(star_alan !== null && user1_key !== "" && user2_key !== "" && dogru_was !== null 
      && user1_star !== null && user2_star !== null

    ){

      
      let _cevap = ""
      if(dogru_was === 1){
        _cevap = "Evet"
      }else{
        _cevap = "Hayır"
      }

      let _isim = "Kimse yıldız kazanamadı."
      let _isim_bitti = "Kimse yıldız kazanamadı."
      if(star_alan === 1){
        _isim = user1_name + " bir yıldız kazandı."
        _isim_bitti = user1_name + " üç yıldıza ulaşarak yarışmayı kazandı. Tebrikler!"
      }else if(star_alan === 2){
        _isim = user2_name  + " bir yıldız kazandı."
        _isim_bitti = user2_name + " üç yıldıza ulaşarak yarışmayı kazandı. Tebrikler!"

      }

      if(user1_star === 3 || user2_star === 3){
        
        setyazi("Bu sorunun cevabı: “"+_cevap+"” idi. "+_isim_bitti)

      }else{
        setyazi("Bu sorunun cevabı: “"+_cevap+"” idi. "+_isim+" Hazırsanız sonraki soru geliyor. Butona ilk basan önce cevap verir.")
      }

      
    }

  }, [user1_key, user2_key, star_alan, dogru_was, user1_star, user2_star])

  useEffect(() => {
    if(yazi !== ""){
      setyazigoster(true)
    }
  }, [yazi])
  




  const getData = async () => {

    let baslikid = 0
    let _dogru_was = 0
    let _star_alan = 0



    await get(child(dbRef, "selected_baslik")).then((snapshot) => {
      baslikid = snapshot.val()
    })

    basliklar_json.basliklar.forEach(element => {
      if(element.id === baslikid){
        setselected_baslik(element.baslik)
      }
    });


    await get(child(dbRef, "star_alan")).then((snapshot) => {
      _star_alan = snapshot.val()
      setstar_alan(_star_alan)
    })
    await get(child(dbRef, "dogru_was")).then((snapshot) => {
      _dogru_was = snapshot.val()
      setdogru_was(_dogru_was)
    })




   
    
    
    

    
    
    setTimeout(function ()  {
        set(ref(db, 'dogru_was'), 0);
        set(ref(db, 'star_alan'), 0);
        set(ref(db, 'cevap_verildi'), "0");

        console.log("sayma bitti");

        

        get(child(dbRef, "user1_key")).then((snapshot) => {
          let _user1_key = snapshot.val()

          get(child(dbRef, "user2_key")).then((snapshot) => {
            let _user2_key = snapshot.val()
  
            console.log(_user1_key);
            console.log(_user2_key);

            get(child(dbRef, "users/"+_user1_key+"/star")).then((snapshot) => {
              let _u1_star = snapshot.val()
    
              get(child(dbRef, "users/"+_user2_key+"/star")).then((snapshot) => {
                let _u2_star= snapshot.val()
    
                console.log("------44546");
                console.log(_u1_star);
                console.log(_u2_star);
                
                console.log("------44546");
                
    
    
                if(_u2_star >= 3 || _u1_star >= 3){
                  
                  console.log("kazanan var");
                  
                }else{
    
                    console.log("devam");
                    
                    navigate("/ekran/soru")
                }
    
    
    
              })
    
    
            })
            
          })
          
        })
        
        

        
    
    


    }, 10000);
    
    
    




  }

  useEffect(() => {
    if(started === 0){
      navigate('/ekran')
    }
  }, [started])

  

  return (
    <div className='tvbg h-screen text-gray-900'>

      <div className='flex justify-center mt-10 items-center space-x-32'>

        <div className='mt-20'>
          <div className='cerceve '>
            <div className='absolute text-3xl font-bold left-0 right-0 mt-9 text-center'>{user1_name}</div>
            <img src={cerceve} alt="logodsd" width={255} />
          </div>
          {user1_star !== null ?
          <div className='flex justify-center  space-x-2 mt-1'>
            <img src={user1_star > 0 ? starts : star} alt="logo" width={44} />
            <img src={user1_star > 1 ? starts : star} alt="logo" width={44} />
            <img src={user1_star > 2 ? starts : star} alt="logo" width={44} />
          </div>
          :
          <div className='h44'></div>
          }
        </div>
        
        <img src={logo} alt="logo" width={420} />

        <div className='mt-20'>

        <div className='cerceve'>
          <div className='absolute text-3xl font-bold left-0 right-0 mt-9 text-center'>{user2_name}</div>
          <img src={cerceve} alt="logodsd" width={255} />
        </div>
        {user2_star !== null ?
        <div className='flex justify-center  space-x-2 mt-1'>
          <img src={user2_star > 0 ? starts : star} alt="logo" width={44} />
          <img src={user2_star > 1 ? starts : star} alt="logo" width={44} />
          <img src={user2_star > 2 ? starts : star} alt="logo" width={44} />
        </div>
          :
          <div className='h44'></div>
        }

        </div>



      </div>

      <div className='text-center text-3xl font-bold mt-8 mb-4 border-b-4 border-slate-900 pb-4 w-80 mx-auto'>KONU BAŞLIĞI</div>

      <div className='text-center text-4xl h-20 '>{selected_baslik}</div>


      <div className='text-4xl mx-44 flex space-x-5 mt-10'>

        <div className='w_7e'> <img src={gpt} width={64} /> </div>

        <div className='flex-1  leading-normal'>
          {yazigoster &&
          <Typewriter
          options={{
            autoStart: true,
            delay: 20,
            cursor: '',
          }}
            onInit={(typewriter) => {
              typewriter.typeString(yazi).start();
            }}
          />

          }
        </div>
        
      </div>

      
      <div className='flex flex-col items-center justify-center mt-10 absolute bottom-8 left-0 right-0 '>
          <img src={alt} width={1000} />
          <div className='mt-3 text-lg text-gray-400'>ChatGPT hata yapabilir. Önemli bilgileri kontrol edin.</div>
        </div>

      
    </div>

  );
}

export default TvCevap;